import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as VideoExpress from "@vonage/video-express";
import MuteAudioButton from "../MuteAudioButton";
import MuteVideoButton from "../MuteVideoButton";
// import SpeakerButton from 'components/SpeakerButton';
// import SpeakerSelector from "../SpeakerSelector";
// import RecordingButton from "../RecordingButton";
import LayoutButton from "../LayoutButton";
// import MuteAll from "../MuteAllButton";
// import ReactionsButton from "../ReactionsButton";
import ScreenSharingButton from "../ScreenSharingButton";
import EndCallButton from "../EndCallButton";
// import VideoFilterButton from "../VideoFilterButton";
import styles from "./styles";
import { useParams } from "react-router";
import { useTheme } from "@material-ui/core";

import MoreOptionsButton from "../MoreOptionsButton";
import PropTypes from "prop-types";
import LocalCallContext from "src/context/LocalCallContext";
import CallInitializationContext from "src/context/CallInitializationContext";
import { VideoChatService } from "src/services/VideoChatService";
import LocalCallTimeOutContext from "src/context/LocalCallTimeOutlContext";
import Modal10 from "src/pages/Popup/Modal10";
import UserContext from "src/context/UserContext";
import Modal9 from "src/pages/Popup/Modal9";
import { toast } from "react-toastify";
import { VonageServices } from "../../../vonageServices";
import RefreshButton from "../RefreshButton";
import RefreshPagePopUp from "src/pages/Popup/RefreshPage";
import ScheduleAppointmentButton from "../ScheduleAppointmentButton";
import AppointmentModalInMeeting from "src/pages/Popup/AppointmentModalOnMeeting";
import Modal4 from "src/pages/Popup/Modal4";
import FourtyFiveMinutesReminderPopUp from "src/pages/Popup/FourtyFiveMinutesReminderPopUp";
import FollowUpMessagePopUp from "src/pages/Popup/FollowUpMessagePopUp";
import NewCustomButton from "../NewCustomButton";

export default function ToolBar({
  room,
  connected,
  cameraPublishing,
  isScreenSharing,
  startScreenSharing,
  stopScreenSharing,
  participants,
  localParticipant,
}) {
  const { roomName } = useParams();
  const theme = useTheme();
  const history = useHistory();
  const [hasAudio, setHasAudio] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [areAllMuted, setAllMuted] = useState(false);
  const classes = styles();
  const isMobileWidth = useMediaQuery(theme.breakpoints.down("xs"));
  const [localUserOnCall, setLocalUserOnCall] = useContext(LocalCallContext);
  const [localCallInitialize, setLocalCallInitialize, localCallInitializeData, setLocalCallInitializeData] = useContext(CallInitializationContext);
  const [callTimeOut, setCallTimeOut, setShowPopUp] = useContext(LocalCallTimeOutContext);
  const [showLeavePopUp, setShowLeavePopUp] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isBothUsersJoined, setIsBothUsersJoined] = useState(false);
  const [loggedUser] = useContext(UserContext);
  const [startArchieve, setStartArchieve] = useState(false);
  const [archiveId, setArchiveId] = useState("")
  const [isFirstSpeakerUpdated, setIsFirstSpeakerUpdated] = useState(false);
  const [showRefreshPagePopup, setShowRefreshPagePopup] = useState(false);
  const [showFortyFiveMinutesReminderPopup, setShowFortyFiveMinutesReminderPopup] = useState(false);
  const [reminderActivated, setReminderActivated] = useState(false);
  const [followUpCreated, setFollowUpCreated] = useState(false);
  const [showFollowUpMessagePopUp, setShowFollowUpMessagePopUp] = useState(false);
  const [participantCount, setParticipantCount] = useState(1);
  const [isUpdatedParticipantCount, setIsUpdatedParticipantCount] = useState(false);
  const [showAppointmentModal, setShowAppointmentModal] = useState(false);
  const [showModalForTherapist, setShowModalForTherapist] = useState(false);

  useEffect(() => {
    initFunction();
  }, []);

  function initFunction() {
    if (history && history.action && history.action != "PUSH") {
      history.push(`/room/${roomName}`)
    }
  }

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };
  useEffect(() => {
    updateScreenWidth();
    window.addEventListener('resize', updateScreenWidth);
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if(participants.length > 0 && !isUpdatedParticipantCount){
      setIsUpdatedParticipantCount(true);
      setParticipantCount(2);
    }

    if (participants.length >= 1 && loggedUser.role == 'THERAPIST') {

      setIsBothUsersJoined(true);
      const delayedFunction = () => {
        delayedFunc();
      };
      const convertMeetingDurationToMiliSeconds = 90 * 60 * 1000;
      const timeoutId = setTimeout(delayedFunction, convertMeetingDurationToMiliSeconds);
      return () => clearTimeout(timeoutId);
    }
  }, [participants])


  useEffect(() => {
    try {
      if (participants && participants.length && participants.length >= 1 && loggedUser.role == 'THERAPIST' && !reminderActivated) {

        setReminderActivated(true);

        const showFourtyFiveMinutesRemainderFunction = () => {
          showFourtyFiveMinutesRemainderFunc();
        };

        const fourtyFiveMinutesToMiliSeconds = 45 * 60 * 1000;
        const fourtyFiveMinutesTimeout = setTimeout(showFourtyFiveMinutesRemainderFunction, fourtyFiveMinutesToMiliSeconds);

        return () => clearTimeout(fourtyFiveMinutesTimeout);

      }
    } catch (error) {

    }
  }, [participants])

  // useEffect(()=> {
  //   if(participants.length >= 1 && !startArchieve && loggedUser.role == 'THERAPIST' ){
  //     setStartArchieve(true);
  //     startArchiveFunc();
  //   }
  // }, [participants]);

  useEffect(() => {
    if (participants.length >= 1 && !startArchieve && loggedUser.role == 'THERAPIST') {
      const timeoutId = setTimeout(() => {
        startArchiveFunc();
      }, 3000);

      return () => clearTimeout(timeoutId);
    }
  }, [participants]);

  // useEffect(()=> {
  //   if(!startArchieve && room?.roomId != null){
  //     setStartArchieve(true);
  //     startArchiveFunc();
  //   }
  // }, [room])

  const startArchiveFunc = async () => {
    try {
      if (room && room.roomId) {
        console.log("room.roomId ", room.roomId);
        const archieveRes = await VonageServices.startArchive(room.roomId);
        if (archieveRes.success) {
          setArchiveId(archieveRes.data);
          localStorage.setItem('archiveId', archieveRes.data);
        }
        setStartArchieve(true);

      }
    } catch (error) {
      console.log(error);
    }

  }

  const delayedFunc = () => {
    toast.success(`Call is ended after 1 hour and 30 minutes!`, {
      position: toast.POSITION.TOP_RIGHT,
      className: "foo-bar",
    });
    endCall();
  }

  const showFourtyFiveMinutesRemainderFunc = () => {
    if (!followUpCreated) {
      setShowFortyFiveMinutesReminderPopup(true);
    }
  }

  const handleMuteAll = () => {
    if (!areAllMuted) {
      participants.map((participant) => participant.camera.disableAudio());

      setAllMuted(true);
    } else {
      participants.map((participant) => participant.camera.enableAudio());
      setAllMuted(false);
    }
  };

  const toggleVideo = () => {
    if (room && room.camera) {
      const { camera } = room;
      const isVideoEnabled = camera.isVideoEnabled();
      if (isVideoEnabled) {
        camera.disableVideo();
        setHasVideo(false);
      } else {
        camera.enableVideo();
        setHasVideo(true);
      }
    }
  };

  const toggleAudio = async () => {
    if (room && room.camera) {
      const camera = room.camera;
      const isAudioEnabled = camera.isAudioEnabled();
      if (isAudioEnabled) {
        camera.disableAudio();
        setHasAudio(false);
      } else {
        camera.enableAudio();
        setHasAudio(true);
        if (!isFirstSpeakerUpdated) {
          setIsFirstSpeakerUpdated(true);
          const res = await VonageServices.updateFirstSpeaker(roomName, loggedUser._id);
        }
      }
    }
  };

  const getVideoSource = () => {
    if (room && room.camera) {
      return room.camera.getVideoDevice();
    }
  };

  const changeVideoSource = (videoId) => {
    room.camera.setVideoDevice(videoId);
  };
  const changeAudioSource = (audioId) => {
    room.camera.setAudioDevice(audioId);
  };

  const changeAudioOutput = async (audioOutputDeviceId) => {
    await VideoExpress.setAudioOutputDevice(audioOutputDeviceId);
  };

  const getCurrentAudioOutput = async () => {
    try {
      const currentAudioOutput = await VideoExpress.getActiveAudioOutputDevice();
      return currentAudioOutput.deviceId;
    } catch (e) {
      return e;
    }
  };

  const getAudioSource = async () => {
    if (room && room.camera) {
      const audioDevice = await room.camera.getAudioDevice();
      return audioDevice.deviceId;
    }
  };

  useEffect(() => {
    if (!localUserOnCall.onOngoingCall) {
      if (room) {
        room.leave();
        history.push(`/video-room/${roomName}/${room.roomId}/end`);
      }

    }
  }, [localUserOnCall])

  const endCall = async () => {
    try {
      await stopArchive()
      const res = await VonageServices.cancellCall(roomName);
      resetContextData();

    } catch (error) {
      console.log(error);
    }

  };

  const stopArchive = async () => {
    const stopArchiveRes = await VonageServices.stopArchive(roomName);
    return stopArchiveRes;
  }

  const leaveBtnClick = () => {

    if (loggedUser.role == 'THERAPIST' && !followUpCreated) {
      setShowFollowUpMessagePopUp(true);
    } else {
      setShowLeavePopUp(true);
    }

  }

  const leaveMeetingPopUpFunction = (showModal, leave) => {
    setShowLeavePopUp(showModal);
    if (leave) {
      endCall();
    }
  };



  const resetContextData = () => {
    const timeoutData = {
      isTimeOutRunning: true,
      userOnMeetingScreen: false,
      showExtendPopup: false,
      callAcceptedByReciever: false,
    };

    setCallTimeOut(timeoutData);

    const callInitializeDataForReset = {
      start: false,
      isInstantMeeting: false,
      isAppointmentBased: false,
      isTranscribeAllowed: false,
      appointmentId: "",
      recieverId: "",
      meetingTime: 30,
      meetingId: "",
      isAudioCall: false
    };

    setLocalCallInitialize(false);
    setLocalCallInitializeData(callInitializeDataForReset);

    const resetDataForCall = {
      callId: "",
      onOngoingCall: false,
      recieversUseDefaultAvatarValue: true,
      recieversAvatarId: "",
      recieversAvatarBackgroundId: "",
      recieversIncogniteModeOn: true,
      meetingMainTherapistId: "",
      isVideoCall: false,
      meetingDuration: 30,
      recieversUserId: "",
      recieversName: "",
      recieverCallRecordingAllowed: false,
      recieversSocketId: "",
      callEndedForBothUsers: false,
      videoSdkToken: "",
      isAudioCall: false
    };
    setLocalUserOnCall(false, resetDataForCall, true, false);
  };

  useEffect(() => {
    if (connected) {
      const isAudioEnabled = room && room.camera && room.camera.isAudioEnabled() ? true : false;
      const isVideoEnabled = room && room.camera && room.camera.isVideoEnabled() ? true : false;
      setHasAudio(isAudioEnabled);
      setHasVideo(isVideoEnabled);
    }
  }, [connected, room]);

  const refreshButtonClick = () => {
    setShowRefreshPagePopup(true);
  }

  const showRefreshPagePopupFunction = (showModal, refresh) => {
    setShowRefreshPagePopup(showModal);
    if (refresh) {
      window.location.reload();
    }
  }

  const scheduleAppointmentButtonClick = () => {
    setShowAppointmentModal(!showAppointmentModal);
  }

  const scheduleAppointmentPopUpFunction = (created) => {
    setShowAppointmentModal(false);
    if (created) {
      setFollowUpCreated(true);
    }

  };

  const followUpMessagePopUpFunction = (closeOnly, showFollowUp) => {
    setShowFollowUpMessagePopUp(false);
    if (!closeOnly) {
      if (showFollowUp) {
        setShowAppointmentModal(true);
      } else {
        setShowLeavePopUp(true);
      }
    }

  };

  const handleCustomButtonClick = () => {
    // Handle button click event to open calendar
    setShowAppointmentModal(true);
  };

  return <div>{screenWidth < 530 ? (
    <div className={classes.toolbarMobileContainer}>
      {!localUserOnCall.isAudioCall && (
        <MuteVideoButton screenWidth={screenWidth} toggleVideo={toggleVideo} hasVideo={hasVideo} classes={classes} changeVideoSource={changeVideoSource} />
      )}

      <MuteAudioButton screenWidth={screenWidth} toggleAudio={toggleAudio} hasAudio={hasAudio} classes={classes} changeAudioSource={changeAudioSource} />
      <MoreOptionsButton screenWidth={screenWidth} classes={classes} participants={participants} room={room} localParticipant={localParticipant} />
      {loggedUser.role == 'THERAPIST' && (<ScheduleAppointmentButton screenWidth={screenWidth} classes={classes} handleScheduleAppointment={scheduleAppointmentButtonClick} />)}
      <LayoutButton screenWidth={screenWidth} classes={classes} room={room} />
      <RefreshButton screenWidth={screenWidth} classes={classes} handleRefreshPage={refreshButtonClick} />
      <EndCallButton screenWidth={screenWidth} classes={classes} handleEndCall={leaveBtnClick} />
    </div>
  ) : (
    <div className={classes.toolbarContainer}>
      <MoreOptionsButton classes={classes} participants={participants} room={room} localParticipant={localParticipant} />
      <MuteAudioButton
        toggleAudio={toggleAudio}
        hasAudio={hasAudio}
        classes={classes}
        changeAudioSource={changeAudioSource}
        getAudioSource={getAudioSource}
        cameraPublishing={cameraPublishing}
        screenWidth={screenWidth}
      />
      {!localUserOnCall.isAudioCall && (
        <MuteVideoButton
          toggleVideo={toggleVideo}
          hasVideo={hasVideo}
          classes={classes}
          getVideoSource={getVideoSource}
          cameraPublishing={cameraPublishing}
          changeVideoSource={changeVideoSource}
          screenWidth={screenWidth}
        />
      )}

      {/* {VideoExpress.hasMediaProcessorSupport() && <VideoFilterButton classes={classes} room={room} />} */}
      {/* <SpeakerButton
        cameraPublishing={cameraPublishing}
        changeAudioOutput={changeAudioOutput}
        getCurrentAudioOutput={getCurrentAudioOutput}
        classes={classes}
      /> */}
      {/* <SpeakerSelector room={room} changeAudioOutput={changeAudioOutput} getCurrentAudioOutput={getCurrentAudioOutput} classes={classes} /> */}

      {/* <RecordingButton room={room} classes={classes} /> */}
      <ScreenSharingButton
        isScreenSharing={isScreenSharing}
        startScreenSharing={startScreenSharing}
        stopScreenSharing={stopScreenSharing}
        classes={classes}
      />
      {loggedUser.role == 'THERAPIST' && <NewCustomButton 
        classes={classes}
        clientId={localUserOnCall.recieversUserId}
        therapistId={loggedUser._id}
        role={loggedUser.role}
      />}

      {loggedUser.role == 'CLIENT' && <NewCustomButton 
        classes={classes}
        clientId={loggedUser._id}
        therapistId={localUserOnCall.recieversUserId}
        role={loggedUser.role}
      />}

      {/* <MuteAll handleMuteAll={handleMuteAll} areAllMuted={areAllMuted} classes={classes} /> */}
      {/* <ReactionsButton handleMuteAll={handleMuteAll} areAllMuted={areAllMuted} classes={classes} room={room} /> */}
      {/* OLD button */}
      {/* {loggedUser.role == 'THERAPIST' && (<ScheduleAppointmentButton screenWidth={screenWidth} classes={classes} handleScheduleAppointment={scheduleAppointmentButtonClick} />)} */}
      <LayoutButton screenWidth={screenWidth} classes={classes} room={room} />
      <RefreshButton screenWidth={screenWidth} classes={classes} handleRefreshPage={refreshButtonClick} />
      <EndCallButton classes={classes} handleEndCall={leaveBtnClick} screenWidth={screenWidth} />
    </div>
  )}
    {showLeavePopUp && <Modal10 showModal={showLeavePopUp} setShowModal={leaveMeetingPopUpFunction} />}
    {showRefreshPagePopup && <RefreshPagePopUp showModal={showRefreshPagePopup} setShowModal={showRefreshPagePopupFunction} />}

    {showModalForTherapist && <Modal4 setShowModal={setShowModalForTherapist} showModal={showModalForTherapist} />}
    {showAppointmentModal && <AppointmentModalInMeeting
      showModal={showAppointmentModal}
      setShowModal={scheduleAppointmentPopUpFunction}
      setShowModalForTherapist={setShowModalForTherapist}
    />}
    {showFortyFiveMinutesReminderPopup && <FourtyFiveMinutesReminderPopUp showModal={showFortyFiveMinutesReminderPopup} setShowModal={setShowFortyFiveMinutesReminderPopup} />}
    {showFollowUpMessagePopUp && <FollowUpMessagePopUp showModal={showFollowUpMessagePopUp} setShowModal={followUpMessagePopUpFunction} />}
  </div>
}
ToolBar.propTypes = {
  room: PropTypes.object, // Add prop types for each prop
  connected: PropTypes.bool,
  cameraPublishing: PropTypes.bool,
  isScreenSharing: PropTypes.bool,
  startScreenSharing: PropTypes.func,
  stopScreenSharing: PropTypes.func,
  participants: PropTypes.array,
  localParticipant: PropTypes.object,
};